/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Cameraswitch as CameraswitchIcon,
  Close as CloseIcon,
  PhotoCamera,
  Send as SendIcon,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { IconButton, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Alert, Box, Button } from "components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import uploadImageAndGetURL from "services/exports/uploadImageAndGetURL";

import Result from "../Result";

function ImageInference({ handleCreateQuery, getTrainingStates }) {
  const [image, setImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const camera = useRef(null);
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const canvasRef = useRef(null);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageSize, setImageSize] = useState(null);

  useEffect(
    () => () => {
      if (image) {
        URL.revokeObjectURL(URL.createObjectURL(image));
      }
    },
    [image]
  );

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.includes("image/")) {
        setError("Please upload an image file");
        return;
      }
      setImage(file);
      setImageSize(formatFileSize(file.size));
      setShowCamera(false);
    }
  };

  const handleCameraCapture = () => {
    const photo = camera.current.takePhoto();
    // Convert base64 to file
    fetch(photo)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "camera-capture.jpg", { type: "image/jpeg" });
        setImage(file);
        setImageSize(formatFileSize(file.size));
        setShowCamera(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getTrainingStates.status !== "fulfilled") return;
    if (!image) return;

    setIsLoading(true);
    setError(null);

    try {
      const { project_code, model_type } = getTrainingStates.data.training;
      const filePath = `platform/${project_code}/${model_type.code}/inference_data/images`;

      const imageUrl = await uploadImageAndGetURL(image, filePath);
      console.log(imageUrl);
      const response = await handleCreateQuery({ url: imageUrl });
      setResult(response);
    } catch (_error) {
      setError(_error.message || "An error occurred while processing your image");
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setImage(null);
    setResult(null);
    setError(null);
    setImageSize(null);
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    setIsImageLoaded(false);
  };

  const handleCloseError = () => {
    setError(null);
  };

  const drawImageAndBoxes = useCallback(() => {
    if (!canvasRef.current || !image) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { alpha: false });

    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.parentElement.getBoundingClientRect();

    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      const maxHeight = 400 * dpr;
      const maxWidth = rect.width * dpr;
      let newWidth = img.width;
      let newHeight = img.height;

      if (newHeight > maxHeight) {
        newWidth *= maxHeight / newHeight;
        newHeight = maxHeight;
      }
      if (newWidth > maxWidth) {
        newHeight *= maxWidth / newWidth;
        newWidth = maxWidth;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      canvas.style.width = `${newWidth / dpr}px`;
      canvas.style.height = `${newHeight / dpr}px`;

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      if (result?.predictions) {
        result.predictions.forEach((prediction) => {
          if (!prediction.coordinates || prediction.coordinates.length !== 4) return;

          const [x1, y1, x2, y2] = prediction.coordinates;

          const scaleX = newWidth / img.width;
          const scaleY = newHeight / img.height;

          const scaledX1 = x1 * scaleX;
          const scaledY1 = y1 * scaleY;
          const scaledWidth = (x2 - x1) * scaleX;
          const scaledHeight = (y2 - y1) * scaleY;

          ctx.strokeStyle = "#00ff00";
          ctx.lineWidth = 2 * dpr;
          ctx.strokeRect(scaledX1, scaledY1, scaledWidth, scaledHeight);

          ctx.fillStyle = "rgba(0, 255, 0, 0.1)";
          ctx.fillRect(scaledX1, scaledY1, scaledWidth, scaledHeight);

          const label = `${prediction.intent} ${prediction.confidence ? `(${(prediction.confidence * 100).toFixed(1)}%)` : ""}`;
          ctx.font = `${12 * dpr}px Arial`;
          const textMetrics = ctx.measureText(label);
          const padding = 4 * dpr;

          ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
          ctx.fillRect(scaledX1, scaledY1 - 24 * dpr, textMetrics.width + padding * 2, 20 * dpr);

          ctx.fillStyle = "#ffffff";
          ctx.fillText(label, scaledX1 + padding, scaledY1 - 10 * dpr);
        });
      }

      URL.revokeObjectURL(img.src);
      setIsImageLoaded(true);
      setCanvasDimensions({ width: newWidth / dpr, height: newHeight / dpr });
    };
  }, [image, result?.predictions]);

  useEffect(() => {
    drawImageAndBoxes();
  }, [drawImageAndBoxes, image, result?.predictions]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          p: { xs: 2, md: 4 },
          borderRadius: 3,
          background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
          border: `1px solid ${theme.palette.divider}`,
          backdropFilter: "blur(10px)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: (_theme) =>
              `linear-gradient(90deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
          },
        }}
      >
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
            handleCloseError={handleCloseError}
          >
            {error}
          </Alert>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          {showCamera ? (
            <Box sx={{ position: "relative", width: "100%", aspectRatio: "3/4" }}>
              <Camera
                ref={camera}
                aspectRatio={3 / 4}
                facingMode="environment"
                errorMessages={{
                  noCameraAccessible: "No camera device accessible",
                  permissionDenied: "Permission denied",
                  switchCamera: "It is not possible to switch camera",
                  canvas: "Canvas error",
                }}
                imageType="image/jpeg"
                imageCompression={0.97}
                imageQuality={1}
                isImageMirror={false}
              />
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  position: "absolute",
                  bottom: 20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1,
                }}
              >
                <IconButton
                  onClick={() => setShowCamera(false)}
                  sx={{ bgcolor: "background.paper" }}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={handleCameraCapture} sx={{ bgcolor: "background.paper" }}>
                  <PhotoCamera />
                </IconButton>
                <IconButton
                  onClick={() => camera.current.switchCamera()}
                  sx={{ bgcolor: "background.paper" }}
                >
                  <CameraswitchIcon />
                </IconButton>
              </Stack>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              {image ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "fit-content",
                    margin: "0 auto",
                  }}
                >
                  <canvas
                    ref={canvasRef}
                    style={{
                      maxHeight: "400px",
                      borderRadius: "8px",
                      display: "block",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                  {imageSize && (
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        left: 8,
                        bgcolor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      {imageSize}
                    </Typography>
                  )}
                  <IconButton
                    onClick={resetState}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      bgcolor: "background.paper",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: `2px dashed ${theme.palette.divider}`,
                    borderRadius: 2,
                    p: 4,
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                  />
                  <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" color="text.secondary">
                      Choose an option to proceed
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="outlined"
                        startIcon={<UploadIcon />}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload Image
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<PhotoCamera />}
                        onClick={() => setShowCamera(true)}
                      >
                        Take Photo
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Box>
          )}

          {image && !showCamera && !result?.predictions && (
            <Button
              variant="contained"
              size="large"
              endIcon={<SendIcon />}
              type="submit"
              disabled={isLoading}
              sx={{
                borderRadius: 2,
                py: 1.5,
                background: (_theme) =>
                  `linear-gradient(135deg, ${_theme.palette.primary.main}, ${_theme.palette.secondary.main})`,
                transition: "transform 0.2s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                },
                "&:disabled": {
                  background: theme.palette.action.disabledBackground,
                },
              }}
            >
              {isLoading ? "Processing..." : "Start inference"}
            </Button>
          )}
        </Box>
      </Paper>

      {result && <Result predictions={result.predictions} duration={result.duration} />}
    </>
  );
}

ImageInference.propTypes = {
  handleCreateQuery: PropTypes.func.isRequired,
  getTrainingStates: PropTypes.object.isRequired,
};

export default ImageInference;
