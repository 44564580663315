import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getIdentity from "config/getIdentity";
import toast from "react-hot-toast";
import cookiesManipulator from "services/browserStorage/cookies";

export const API_URL = process.env.REACT_APP_API_URL;
export const INTERACTION_URL = process.env.REACT_APP_INTERACTION_URL;

const whiteList = ["login", "createQuery"];

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers, { endpoint }) => {
    headers.set("x-platform", 1);
    headers.set("x-origin", 1);
    headers.set("x-version", 1);
    headers.set("Content-Type", "application/json");
    const token = cookiesManipulator.getCookies("token");
    const identifier = await getIdentity();
    headers.set("x-identifier", identifier);

    if (token && !whiteList.includes(endpoint)) {
      headers.set("x-auth", token);
    } else headers.delete("x-auth");

    return headers;
  },
});

const baseQueryWithTokenCheck = async (args, api, extraOptions) => {
  console.log(JSON.stringify(args), JSON.stringify(api));
  let result = null;

  if (!whiteList.includes(api.endpoint)) {
    cookiesManipulator.extendAuthExpiry();
  }
  result = await baseQuery(args, api, extraOptions);

  if (result?.error) {
    console.error("api error", result.error, args, api.endpoint);
    if (typeof result.error === "string" && result.error.includes("Internal Server Error")) {
      toast.error("Internal Server Error");
    } else if (typeof result.error.error === "string") {
      toast.error(result.error.error);
    } else toast.error(result.error.data.message);
    return result;
  }
  if (!whiteList.includes(api.endpoint) && result.data.message === "Authentication failed.") {
    // use error codes
    toast.error(result.data.message);
    await cookiesManipulator.removeAuth();
    await localStorage.removeItem("sessionState");
    await localStorage.removeItem("email");
    window.location.reload();
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithTokenCheck,
  endpoints: () => ({}),
});

export default api;
