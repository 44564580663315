function hexToArrayBuffer(hex) {
  const bytes = new Uint8Array(Math.ceil(hex.length / 2));
  for (let i = 0; i < bytes.length; i += 1) {
    bytes[i] = parseInt(hex.substr(i * 2, 2), 16);
  }
  return bytes.buffer;
}

const isCryptoSupported = () => {
  try {
    return !!(
      typeof window !== "undefined" &&
      window.crypto &&
      window.crypto.subtle &&
      window.crypto.subtle.importKey
    );
  } catch (e) {
    return false;
  }
};

async function decrypt(encryptedHex) {
  const keyHex = process.env.REACT_APP_ENCRYPTION_KEY;
  const ivHex = process.env.REACT_APP_ENCRYPTION_IV;

  if (!isCryptoSupported()) {
    alert("Web Crypto API is not supported on this device");
    throw new Error("Web Crypto API is not supported on this device");
  }

  try {
    const keyBuffer = hexToArrayBuffer(keyHex);
    const ivBuffer = hexToArrayBuffer(ivHex);

    const key = await window.crypto.subtle.importKey("raw", keyBuffer, { name: "AES-CBC" }, false, [
      "decrypt",
    ]);

    const encryptedBuffer = hexToArrayBuffer(encryptedHex);

    const decryptedBuffer = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: ivBuffer },
      key,
      encryptedBuffer
    );

    const decryptedText = new TextDecoder().decode(decryptedBuffer);
    return decryptedText;
  } catch (error) {
    alert("Decryption failed");
    console.error("Decryption failed:", error);
    throw error;
  }
}

export default decrypt;
