import { Buffer } from "buffer";
import convertToBase64 from "services/exports/convertToBase64";
import uploadToS3 from "services/exports/uploadToS3";
import uniqueId from "services/utils/generateUniqueId";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const compressImage = (imageFile) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const { width } = img;
        const { height } = img;

        let quality = 0.7;
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        let compressedBase64 = canvas.toDataURL(imageFile.type, quality);
        while (compressedBase64.length > MAX_FILE_SIZE * 1.37 && quality > 0.1) {
          quality -= 0.1;
          compressedBase64 = canvas.toDataURL(imageFile.type, quality);
        }

        resolve(compressedBase64);
      };
    };
  });

const uploadImageAndGetURL = async (image, filePath = "training_data", shouldResize = false) => {
  const imageBuff = shouldResize ? await compressImage(image) : await convertToBase64(image);

  const fileName = image.name || "unknown";
  const fileType = image.type || "image/jpeg";
  const metaData = {
    Body: Buffer.from(imageBuff.replace(/^data:image\/\w+;base64,/, ""), "base64"),
    Key: `${filePath}/${uniqueId()}.jpeg`,
    ContentType: fileType === "image/jpeg" ? "image/jpeg" : "image/png",
  };

  const url = await uploadToS3(metaData);
  return url;
};

export default uploadImageAndGetURL;
